 <template>
	<el-dialog :title="page_title" top="1vh" width="1080px" :visible.sync="is_show_in_page">
		
		<!-- 表单 -->
		<el-form label-width="80px" style="margin:-15px 0 20px 0">


			<div class="big_tit" style="margin:0">发车/到货</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="发车条件" style="width:250px">
					<el-select @change="sava('condition_of_tord_start')" class="el_inner_width" v-model="form.condition_of_tord_start">
						<el-option label="无" value="1"></el-option>
						<el-option label="区划校验" value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="el_form_item" label="发车凭证" style="width:250px">
					<el-select @change="sava('truck_tord_start_voucher_upl_mode')" class="el_inner_width" v-model="form.truck_tord_start_voucher_upl_mode">
						<el-option label="自愿上传" value="1"></el-option>
						<el-option label="提示上传" value="2"></el-option>
						<el-option label="强制上传" value="3"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="el_form_item" label="到货条件" style="width:250px">
					<el-select @change="sava('condition_of_tord_arrived')" class="el_inner_width" v-model="form.condition_of_tord_arrived">
						<el-option label="无" value="1"></el-option>
						<el-option label="区划校验" value="2"></el-option>
						<!-- <el-option label="间隔30分钟" value="3"></el-option> -->
					</el-select>
				</el-form-item>
				<!-- <el-form-item class="el_form_item" label="到货凭证" style="width:250px">
					<el-select @change="sava('truck_tord_arrived_voucher_upl_mode')" class="el_inner_width" v-model="form.truck_tord_arrived_voucher_upl_mode">
						<el-option label="自愿上传" value="1"></el-option>
						<el-option label="提示上传" value="2"></el-option>
						<el-option label="强制上传" value="3"></el-option>
					</el-select>
				</el-form-item> -->
			</div>


			<div class="big_tit">允许最大单价/预付款最大比例(0 - 100%)</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="最大单价" style="width:490px">
					<el-input v-model="form.freight_unit_price_max" type="number">
						<el-button slot="append">元/公里</el-button>
						<el-button @click="sava('freight_unit_price_max')" slot="append">修改</el-button>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="最大比例" style="width:490px;margin-left: 10px;">
					<el-input v-model="form.freight_not_arrived_pay_percent_max" type="number">
						<el-button slot="append">%</el-button>
						<el-button @click="sava('freight_not_arrived_pay_percent_max')" slot="append">修改</el-button>
					</el-input>
				</el-form-item>
			</div>


			<div class="big_tit">申请开票条件/额外服务费率/额外服务费计算方式</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="开票条件" style="width:320px">
					<el-select @change="sava('condition_of_invoice_apply')" class="el_inner_width" v-model="form.condition_of_invoice_apply">
						<el-option label="部分支付运费" value="1"></el-option>
						<el-option label="完全支付运费" value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="el_form_item" label="服务费率" style="width:364px;">
					<el-input v-model="form.extra_service_charge_rate" type="number">
						<el-button slot="append">个点</el-button>
						<el-button @click="sava('extra_service_charge_rate')" slot="append">修改</el-button>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="计算方式" style="width:320px;">
					<el-select @change="sava('extra_service_charge_type')" class="el_inner_width" v-model="form.extra_service_charge_type">
						<el-option label="除法" value="1"></el-option>
						<el-option label="乘法" value="2"></el-option>
					</el-select>
				</el-form-item>
			</div>
			
		</el-form>
	</el-dialog>
</template>

<script>
	export default {
		props:{
			is_show:Number,
			truck_tord_num:String
		},
		data() {
			return {
				
				//是否显示
				is_show_in_page:false,

				//页面标题
				page_title:'',

				//货主参数
				form:{
					
					//开票申请条件(1:部分支付,2:完全支付)
					condition_of_invoice_apply:'',//

					//额外服务费率
					extra_service_charge_rate:'',//

					//额外服务费计算方式(1:除法模式,2:乘法模式)
					extra_service_charge_type:'',//

					//允许运费单价最大值(单位:元/公里)
					freight_unit_price_max:'',//

					//运费未到货时付款总量占总运费最大比例
					freight_not_arrived_pay_percent_max:'',//

					//到货后自动校验轨迹(1:自动校验,2:不自动校验)
					auto_trajectory_check_of_tord_arrived:'',

					//发车条件(1:无,2:使用经纬度校验)
					condition_of_tord_start:'',//

					//到货条件(1:无,2:使用经纬度校验,3:时间间隔30分钟)
					condition_of_tord_arrived:'',//

					//发车凭证上传模式(1:自愿,2:自动打开上传界面,3:强制上传)
					truck_tord_start_voucher_upl_mode:'',//

					//到货凭证上传模式(1:自愿,2:自动打开上传界面,3:强制上传)
					truck_tord_arrived_voucher_upl_mode:'',//
				}
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){

					//读取数据
					this.get_tord_set()

					//页面标题
					this.page_title=`运单 ${this.truck_tord_num} 的参数`

					//打开弹出层
					this.is_show_in_page=true;

				}else{

					//关闭弹出层
					this.is_show_in_page=false;
				}
			}
		},
		methods: {

			//保存
			sava(key){

				//表单
				let form={
					mod:'truck_tord_real_time',
					ctr:'tord_set_edit_by_admin',
					truck_tord_num:this.truck_tord_num,
					set:{}
				}

				//置入修改后的参数
				form.set[key]=this.form[key]

				//特殊处理
				if(key=='freight_not_arrived_pay_percent_max'){//预付款最大比例(0 - 100%)
					form.set.freight_not_arrived_pay_percent_max=this.form.freight_not_arrived_pay_percent_max/100
				}

				//提交数据
				this.$my.net.req({
					data:form,
					callback:(data)=>{
						this.$my.other.msg({
							type:'success',
							str:'修改成功'
						});
					},
				});
			},

			//读取数据
			get_tord_set(){

				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_set_list_by_admin',
						truck_tord_num:this.truck_tord_num
					},
					callback:(data)=>{

						var data=data.list[0]
						data.freight_not_arrived_pay_percent_max=(data.freight_not_arrived_pay_percent_max*100).toFixed(0)

						//置入数据
						this.form=data
					}
				})
			},
		}
	}
</script>
<style lang="scss" scoped>

	.el_form_item{
		margin: 0;
	}
	.big_tit{
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
	}
	.tab1_inner{
		padding: 15px 15px 0 15px;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	.el_inner_width{
		width:94%;
	}

	.tord_real_time_add_bottom_btns{
		margin-top: 40px;
		text-align: center;
	}

	.el-dialog-s{
		z-index: 11;
	}

</style>