 <template>
	<el-dialog 
		custom-class="el-dialog-s" append-to-body
		top="1vh" title="结算运费" width="1050px" 
		:visible.sync="this_is_show"
	>
		<el-form class="form_ordinary" label-width="97px" style="padding-top:0">

			<div class="big_tit" style="margin:0">车主/司机/货车</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="车主姓名" style="width:320px">
					<el-input class="el_inner_width" v-model="truck_owner_info.name" disabled></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="司机姓名" style="width:320px">
					<el-input class="el_inner_width" v-model="driver_info.name" disabled></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="车牌号" style="width:320px">
					<el-input class="el_inner_width" v-model="truck_info.truck_plate_num" disabled></el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="车主电话" style="width:320px">
					<el-input class="el_inner_width" v-model="truck_owner_info.tel" disabled></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="司机电话" style="width:320px">
					<el-input class="el_inner_width" v-model="driver_info.tel" disabled></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="货车信息" style="width:320px">
					<el-input class="el_inner_width" v-model="truck_info.truck_type_name_text" disabled></el-input>
				</el-form-item>
			</div>


			<div class="big_tit">支付/结算状态</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="货主应付" style="width:388px">
					<el-input class="el_inner_width" v-model="cost_info.freight_total" disabled>
						<el-button type="success" slot="append" style="width:98px">元</el-button>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="平台应结算" style="width:588px">
					<el-input class="el_inner_width" v-model="cost_info.give_carrier_total" disabled>
						<el-button type="success" slot="append" style="width:98px">元</el-button>
					</el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="货主已付" style="width:388px">
					<el-input class="el_inner_width" v-model="cost_info.shipper_payed" disabled>
						<el-button type="success" slot="append">支付记录</el-button>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="平台已结算" style="width:588px">
					<el-input class="el_inner_width" v-model="cost_info.settled_total" disabled>
						<el-button type="success" slot="append">支付记录</el-button>
					</el-input>
				</el-form-item>
			</div>


			<div class="big_tit">本次结算</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="结算类型" style="width:388px">
					<el-select class="el_inner_width" v-model="this_settle_type" @change="get_this_settle_info">
						<el-option label="现金" value="1"></el-option>
						<el-option label="燃油费" value="2"></el-option>
						<el-option label="过路费" value="3"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="el_form_item" label="结算金额" style="width:588px">
					<el-input class="el_inner_width" v-model="this_settle_freight" type="number" @change="get_this_settle_info" clearable>
						<el-button type="success" slot="append" style="width:98px">元</el-button>
					</el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="货主需付" style="width:388px">
					<el-input class="el_inner_width" v-model="this_shipper_need_pay" type="number" :disabled="cost_info.calc_mode==1" clearable>
						<el-button type="success" slot="append" style="width:98px">元</el-button>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="结算备注" style="width:588px">
					<el-input class="el_inner_width" v-model="settle_mark" clearable></el-input>
				</el-form-item>
			</div>
			<div class="bottom_btns">
				<el-button type="primary" @click="sub()">提交</el-button>
			</div>
		</el-form>

	</el-dialog>
</template>

<script>
	export default {
		props:{
			tord_info:Object,
			is_show:Number
		},
		data() {
			return {

				//页面是否显示
				this_is_show:false,

				//运单编号
				truck_tord_num:'',

				//车主信息
				truck_owner_info:{},

				//司机信息
				driver_info:{},

				//货车信息
				truck_info:{},

				//已付资金信息
				cost_info:{},

				//本次支付类型(1:现金,2:燃油费,3:过路费)
				this_settle_type:'1',

				//本次结算金额
				this_settle_freight:0,

				//剩余待结算金额
				settle_surplus:0,

				//本次支付总金额
				this_shipper_need_pay:0,

				//剩余待支付总额
				shipper_pay_surplus:0,

				//备注
				settle_mark:'',
			}
		},
		watch:{
			is_show(new_data){
				if(new_data==0){
					this.this_is_show=false
				}else {
					this.this_is_show=true
					this.init();
				}
			},
		},
		methods: {

			//提交
			sub(){

				if(['1','2','3'].indexOf(this.this_settle_type)==-1){
					this.$my.other.msg({
						type:'warning',
						str:"不存在的结算类型, 请刷新后再试"
					});
					return;
				}

				if(!this.$my.check.is_num(this.this_settle_freight)){
					this.$my.other.msg({
						type:'warning',
						str:"请输入本次结算金额"
					});
					return;
				}

				if(this.this_settle_freight<=0){
					this.$my.other.msg({
						type:'warning',
						str:"本次结算金额不能小等于0"
					});
					return;
				}

				if(this.this_settle_freight>this.settle_surplus){
					this.$my.other.msg({
						type:'warning',
						str:"本次结算金额超过最大可结算金额"
					});
					return;
				}

				//调接口
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_settle_creat_by_admin',
						settle_list:[
							{
								truck_tord_num:this.tord_info.truck_tord_num,
								this_shipper_need_pay:this.this_shipper_need_pay,
								this_settle_freight:this.this_settle_freight,
								this_settle_type:this.this_settle_type,
								settle_mark:this.settle_mark,
							}
						]
					},
					callback:(data)=>{
						this.$my.other.msg({
							type:'success',
							str:"操作成功"
						});
						this.this_is_show=false;
						this.this_settle_type='1';
						this.settle_mark='';
						this.$emit("settle_end");
					}
				});
			},

			//页面初始化
			init(){

				let tord_info=this.tord_info

				if(Object.keys(tord_info).length==0){
					return;
				}

				//运单编号
				this.truck_tord_num=tord_info.truck_tord_num

				//车主信息
				this.truck_owner_info=tord_info.truck_owner_info

				//司机信息
				this.driver_info=tord_info.driver_info

				//货车信息
				this.truck_info=tord_info.truck_info

				//本次结算金额
				this.this_settle_freight=0

				//剩余待结算金额
				this.settle_surplus=0;

				//本次货主需支付金额
				this.this_shipper_need_pay=0

				//剩余待支付金额
				this.shipper_pay_surplus=0;

				//计算本次结算信息
				this.get_this_settle_info();
			},

			//计算本次结算数据
			get_this_settle_info(){

				// if(this.this_settle_freight<=0){
				// 	this.$my.other.msg({
				// 		type:'warning',
				// 		str:"本次结算金额不能小等于0"
				// 	});
				// 	return;
				// }

				if(this.this_settle_freight>this.settle_surplus){
					this.$my.other.msg({
						type:'warning',
						str:"本次结算金额超过最大可结算金额"
					});
					return;
				}

				//去服务器读取
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'get_tord_settle_info_by_admin',
						truck_tord_num:this.tord_info.truck_tord_num,
						this_settle_freight:this.this_settle_freight,
						this_settle_type:this.this_settle_type,
					},
					callback:(data)=>{

						//剩余待结算金额
						this.settle_surplus=data.settle_surplus

						//剩余待支付金额
						this.shipper_pay_surplus=data.shipper_pay_surplus

						//需要货主支付
						this.this_shipper_need_pay=data.this_shipper_need_pay

						//本次结算
						this.this_settle_freight=data.this_settle_freight

						//支付信息
						this.cost_info=data.cost_info
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.el_form_item{
		margin: 0;
	}
	.big_tit{
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
	}
	.tab1_inner{
		padding: 15px 15px 0 15px;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	.el_inner_width{
		width:94%;
	}

	.bottom_btns{
		text-align: center;
		margin-top: 20px;
		padding-top: 20px;
		background-color: #fff;
		border-top: 1px solid #ccc;
	}

	.form_ordinary{
		padding: 10px 0;
		overflow-y:auto;
	}
	.el-dialog-s{
		z-index: 11;
	}
</style>